<template>
  <div v-if="extra">
    <h6>{{ title }} {{ $store.getters.device(extra.serial)[extra.level_name] }}m [{{ $store.getters.device(extra.serial)[extra.percentage_name] }}%]</h6>
    <div class="progress" style="height: 10px;">
      <div class="progress-bar progress-bar-striped" role="progressbar" :aria-valuenow="$store.getters.device(extra.serial)[extra.percentage_name]" aria-valuemin="0" aria-valuemax="100" :style="'width: ' + $store.getters.device(extra.serial)[extra.percentage_name] + '%;'"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra']
}
</script>
