<template>
  <div v-if="extra">
    <h6>{{ title }} {{ $store.getters.device(extra.serial)[extra.name] }} {{ extra.unit }}</h6>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra']
}
</script>
