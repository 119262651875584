<template>
  <div>
    <div class="row">
      <div class="col text-center">
        <img v-if="$store.getters.device(extra.serial)[extra.name]" :src="require('@/assets/pump-true.png')" style="width: 80px !important;">
        <img v-else :src="require('@/assets/pump-false.png')" style="width: 80px !important;">
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h4>{{ title }}</h4>
      </div>
    </div>
    <div class="row gx-1">
      <div class="col text-end">
        <span style="font-weight: bold" v-if="$store.getters.device(extra.serial)[extra.name]">1</span>
        <span style="font-weight: bold" v-else>0</span>
      </div>
      <div class="col text-start">Hz</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra']
}
</script>
