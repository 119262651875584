<template>
  <div v-if="extra">
    <h4>{{ title }}</h4>
    <div class="row">
      <!-- Icon -->
      <div class="col-5">
        <img :src="require('@/assets/pump-' + $store.getters.device(extra.serial)[extra.name] + '.png')" class="img-fluid">
      </div>
      <!--
      <div class="col">
        <p>&nbsp;</p>
        <h4 v-if="$store.getters.device(extra.serial)[extra.name] === false || 0">0 Hz</h4>
        <h4 v-else>50 Hz</h4>
      </div>

      <div class="col">
        <p>&nbsp;</p>
        <h4 v-if="Boolean($store.getters.device(extra.serial)[extra.name]) === false || 0">Klapp <b>kinni</b></h4>
        <h4 v-else>Klapp <b>lahti</b></h4>
      </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra'],
  data () {
    return {}
  }
}
</script>
