<template>
  <div>
    <div v-if="layout !== null && $store.state.websocket === true" class="container mt-4">
      <!-- Title -->
      <div class="row">
        <div class="col">
          <h3>{{ station.name }}</h3>
        </div>
      </div>
      <!-- View -->
      <div v-if="layout !== null" class="row g-1">
        <!-- Render view from layout conf -->
        <div v-for="(view, index) in layout" :key="index" :class="view.width">
          <div class="card">
            <div class="card-body p-2">
              <h4 v-if="view.title">{{ view.title }}</h4>
              <component v-for="(component, index) in view.components" :key="index" :title="component.title"
                         :station="station" :extra="component.extra" v-bind:is="component.type"
                         class="mb-3" :class="{'component-offline': 2 === 1}"></component>
            </div>
          </div>
        </div>
      </div>
      <!-- Debug data -->
      <div v-if="host !== 'narvakom.pilv.app'" class="accordion mt-4" id="debug">
        <div class="accordion-item">
          <!-- Station config -->
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              Station config
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <pre>
                {{ station }}
              </pre>
            </div>
          </div>
        </div>
        <!-- Station layout -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Station layout
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <pre>
                {{ layout }}
              </pre>
            </div>
          </div>
        </div>
        <!-- Station devices -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Station devices
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <pre v-for="device in station.serials" :key="device">
                <h4>{{ device }}</h4>
                {{ $store.getters.device(device) }}
              </pre>
            </div>
          </div>
        </div>
      </div>
      <!-- End debug data -->
    </div>
    <div v-else class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Divider from '../components/UI/Divider.vue'
import Pump from '../components/UI/Pump'
import PumpStatus from '../components/UI/PumpStatus'
import Alarms from '../components/UI/Alarms'
import Tank from '../components/UI/Tank'
import Information from '../components/UI/Information'
import Stage1 from '../components/UI/Stage1'
import LineChart from '../components/UI/LineChart'
import OverflowPump from '../components/UI/OverflowPump'
import OverflowTank from '../components/UI/OverflowTank'
import SimpleData from '../components/UI/SimpleData'
import ResetButton from '../components/UI/ResetButton'
import Level from '../components/UI/Level'
import Config from '../components/UI/Config'
import ActionButton from '../components/UI/ActionButton'

export default {
  components: {
    Divider,
    Tank,
    Pump,
    PumpStatus,
    Alarms,
    Information,
    Stage1,
    LineChart,
    OverflowPump,
    OverflowTank,
    SimpleData,
    ResetButton,
    Level,
    Config,
    ActionButton
  },
  metaInfo () {
    return {
      title: this.station.name
    }
  },
  data () {
    return {
      station: {
        name: '-'
      },
      layout: null,
      host: location.host
    }
  },
  watch: {
    '$route.params.station_id': function () {
      this.getStation()
    }
  },
  sockets: {
    connect: function () {
      this.subscribe()
    },
    data: function (data) {
      this.$store.commit('updateData', data)
    }
  },
  mounted () {
    this.getStation()
  },
  methods: {
    // Get station info from local data
    getStation () {
      // Fetch local station data from memory
      this.station = this.$store.state.stations.find(station => station.id === this.$route.params.station_id)

      // Subscribe to station serials
      this.subscribe()

      const _this = this

      // Fetch layout from server
      axios.get(this.$store.state.apiUrl + '/layouts?id=' + this.$route.params.station_id)
        .then(function (response) {
          console.log('Station layout config:', response.data)
          _this.layout = response.data
        })
        .catch(function (error) {
          console.log('Failed to fetch station layout config:', error)
        })
    },
    // Request station socket rooms
    subscribe () {
      console.log('subscribed to', this.station.serials)
      this.$socket.emit('requestRooms', this.station.serials)
      // Request station alarms
      console.log('Requesting alarmlog for group_id: ' + this.station.alarm_group_id)
      this.$socket.emit('alarmLog', { group_id: this.station.alarm_group_id })
    }
  }
}
</script>
