<template>
  <button class="btn btn-outline-primary float-end btn-sm" @click="buttonClick">{{ title }}</button>
</template>

<script>
export default {
  props: ['title', 'extra'],
  methods: {
    buttonClick () {
      console.log('doAction', this.extra)
      this.$socket.emit('doAction', this.extra)
    }
  }
}
</script>
