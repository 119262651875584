<template>
  <div>
    <h4>{{ title }}</h4>
    <div v-for="(status, index) in extra" :key="index">
      <h5>
        <span class="badge bg-success" v-if="status.inverted && !Boolean($store.getters.device(status.serial)[status.name]) || !status.inverted && Boolean($store.getters.device(status.serial)[status.name])">OK</span>
        <span class="badge bg-danger" v-if="status.inverted && Boolean($store.getters.device(status.serial)[status.name]) || !status.inverted && !Boolean($store.getters.device(status.serial)[status.name])">NOT OK</span>
        {{ status.note }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra']
}
</script>
