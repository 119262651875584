<template>
  <button class="btn float-end btn-sm ms-2" :class="this.extra.class" @click="buttonClick">{{ title }}</button>
</template>

<script>
export default {
  props: ['title', 'extra'],
  methods: {
    buttonClick () {
      console.log('controlData', this.extra.parameters)
      this.$socket.emit('controlData', this.extra.parameters)
    }
  }
}
</script>
