<template>
  <div>
    <!-- Status icon -->
    <div class="row">
      <div class="col text-center">
        <img v-if="$store.getters.device(extra.serial)[extra.name] < 4" :src="require('@/assets/pump-' + $store.getters.device(extra.serial)[extra.name] + '.png')" style="width: 80px !important;">
        <img v-else :src="require('@/assets/pump-true.png')" style="width: 80px !important;">
      </div>
    </div>
    <!-- Title -->
    <div class="row">
      <div class="col text-center">
        <h5>{{ title }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra'],
  data () {
    return {}
  }
}
</script>
