<template>
  <div>
    <!-- Settings button -->
    <div class="row gx-1 mt-2" v-if="$store.getters.permissions('write') && Object.keys(input).length > 0">
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="'#modal-' + extra.serial + '-' + modalState" @click="modalState = Date.now()">{{ title }}</button>
    </div>
    <!-- Settings modal -->
    <div class="modal fade" :id="'modal-' + extra.serial + '-' + modalState" ref="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Sulge"></button>
          </div>
          <div class="modal-body" v-if="Object.keys(input).length > 0">
            <!-- Alerts -->
            <div v-if="status === 1" class="alert alert-success" role="alert">
              Muudatused salvestatud!
            </div>
            <div v-if="status === -1" class="alert alert-danger" role="alert">
              Salvestamine ebaõnnestus!
            </div>
            <!-- Editable properties -->
            <div class="row gx-1 mb-2" v-for="(parameter, index) in extra.parameters" :key="index">
              <div class="col text-end">{{ parameter.title }}</div>
              <div class="col text-start">
                <input type="text" v-model="input[parameter.name]" style="width:40px"> {{ parameter.unit }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="setValues()" data-bs-dismiss="modal">Sulge</button>
            <button type="button" class="btn btn-primary" @click="save()">Salvesta muudatused</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra'],
  data () {
    return {
      status: 0,
      modalState: null,
      input: {}
    }
  },
  watch: {
    // Watch for route and serial change to update current input field values
    '$route.params.station_id': function () {
      // this.setValues()
    },
    modalState: function () {
      this.setValues()
    }
  },
  created () {
    this.setValues()
  },
  methods: {
    // Load current values into input fields
    setValues () {
      this.extra.parameters.forEach((parameter) => {
        this.$set(this.input, parameter.name, this.$store.getters.device(this.extra.serial)[parameter.name])
      })
    },
    // Send set values to socket
    save () {
      // console.log('Saving', { serial: this.extra.serial, data: this.input })
      this.$socket.emit('controlData', { serial: this.extra.serial, data: this.input })
      this.status = 1
    }
  }
}
</script>
