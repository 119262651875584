<template>
  <div v-if="extra">
    <h6>{{ title }} ({{ $store.getters.device(extra.serial)[extra.name_for_meter] }}m) [{{ $store.getters.device(extra.serial)[extra.name_for_percent] }}%]</h6>
    <div class="progress" style="height: 50px;">
      <div class="progress-bar progress-bar-striped" :class="levelState($store.getters.device(extra.serial)[extra.name_for_percent])" role="progressbar" :aria-valuenow="$store.getters.device(extra.serial)[extra.name_for_percent]" aria-valuemin="0" aria-valuemax="100" :style="'width: ' + $store.getters.device(extra.serial)[extra.name_for_percent] + '%;'"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra'],
  data () {
    return {
      state: ''
    }
  },
  methods: {
    levelState (value) {
      if (value >= 25 && value <= 50) {
        return 'bg-warning'
      } else if (value < 25) {
        return 'bg-danger'
      } else {
        return 'bg-primary'
      }
    }
  }
}
</script>
