<template>
  <div>
    <h4>{{ title }}</h4>
    <div class="row">
      <div class="col px-4">
        <!-- Upper -->
        <div class="row" v-if="$store.getters.device(extra.serial)[extra.name_upper] === false" style="height: 80px;">
          <div class="col-4" style="background: #ececec">&nbsp;</div>
          <div class="col-8 pt-4" style="color: red">Ujuk OFF!</div>
        </div>
        <div class="row" v-else style="height: 80px;">
          <div class="col-4" style="background: blue">&nbsp;</div>
          <div class="col-8 pt-4" style="color: blue">Ujuk ON!</div>
        </div>
        <!-- Lower -->
        <div class="row" v-if="$store.getters.device(extra.serial)[extra.name_lower] === false" style="height: 80px;">
          <div class="col-4" style="background: #ececec">&nbsp;</div>
          <div class="col-8 pt-4" style="color: red">Ujuk OFF!</div>
        </div>
        <div class="row" v-else style="height: 80px;">
          <div class="col-4" style="background: blue">&nbsp;</div>
          <div class="col-8 pt-4" style="color: blue">Ujuk ON!</div>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra']
}
</script>
