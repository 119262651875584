<template>
  <div>
    <div id="chart" v-if="station"></div>
  </div>
</template>

<script>
export default {
  props: ['extra', 'station'],
  data () {
    return {
      simpleTimelineChartHandler: null,
      chart: {
        object: null,
        options: {
          divId: 'chart',
          dataSeriesNames: null,
          niceNames: null
        }
      }
    }
  },
  sockets: {
    dataLog: function (data) {
      this.simpleTimelineChartHandler.setData(data)
    }
  },
  watch: {
    '$route.params.station_id': function () {
      this.setOptions()
    }
  },
  mounted () {
    console.log('linechart:', this.station)
    this.setOptions()
  },
  methods: {
    setOptions () {
      this.simpleTimelineChartHandler = null

      this.chart.options.dataSeriesNames = this.station.chart.series
      this.chart.options.niceNames = this.station.chart.niceNames

      this.simpleTimelineChartHandler = new window.SimpleTimelineChart(JSON.parse(JSON.stringify(this.chart.options)))

      // Options set, send socket request
      this.getData()
    },
    getData () {
      const _this = this
      this.station.chart.series.forEach(function (series) {
        _this.$socket.emit('dataLog', { serial: series.serial, name: series.name, timestampFrom: Date.now() - 7 * 24 * 3600 * 1000 })
      })
    }
  }
}
</script>
