<template>
  <div>
    <div class="row">
      <div class="col">
        <div v-if="extra.mode === 'simple'">
          <img v-if="$store.getters.device(extra.serial)[extra.name] > 0" :src="require('@/assets/pump-true.png')" style="width: 80px !important;">
          <img v-else :src="require('@/assets/pump-false.png')" style="width: 80px !important;">
          <!--
          <img :src="require('@/assets/pump-' + $store.getters.device(extra.serial).pump_status_color_value + '.png')" style="width: 80px !important;">
          -->
        </div>
        <div v-else>
          <img :src="require('@/assets/pump-' + $store.getters.device(extra.serial)[extra.name] + '.png')" style="width: 80px !important;">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h5>{{ title }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'extra']
}
</script>
